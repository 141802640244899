<template>
  <div class="container">
    <div class="table-wrap horizontal headless-table" id='bbs-table' >
      <!-- <div class="table-header" style="visible:none">
        <div class="table-header-item article-ref">출처</div>
        <div class="table-header-item article-title">제목</div>
        <div class="table-header-item article-time">게재일자</div>
      </div> -->
      <div v-for="(content, index) in tableContents" 
      :key=index class="table-row"
      v-on:click="sendTo(index)" ref='rows'> 
        <div class="table-row-item article-category" :id="'table-row-category-' + index">
          {{content.category.S}}
        </div>
        <div class="table-row-item article-title" :id="'table-row-title-' + index">
          <p class="ellipsis-1">{{content.articleTitle.S}}</p>
        </div>

        <div class="table-row-item article-time" :id="'table-row-time-' + index">
          <time :date="content.articleId.N">{{content.timegap}} </time>
        </div>
        <div class="table-row-item description" :id="'table-row-desc-' + index">
          <p class="ellipsis-2" v-html="content.description.S"></p>
        </div>
        <div v-if="content.thumbnail" class="table-row-item thumbnail" :id="'table-row-thumbnail-' + index">
          <img :src="content.thumbnail" alt="썸넬">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BBSList',
  props: {
    // tableContents: Object,
    // lastContent: null,
  },
  data (){
    return {
    tableContents: [],

    }
  },
  methods: {
  },
  computed: {

  },
  created () {
    this.$store.state.bbsContents.lastContent = null;
    this.$store.state.bbsContents.tableContents = []
    this.$store.dispatch("getBbsList")
    this.tableContents = this.$store.state.bbsContents.tableContents;
  },
  renderTracked() {

  },
  activated (){
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
