import axios from 'axios'

const getAPI = axios.create({

  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

})

export default {
    async getCrawledArticles (last) {
      var response;
      if (last===null){
        response = await getAPI.get('crawled/community');
      } else {
        const params = {
          'last': last,
        }
        response = await getAPI.get('crawled/community?last=' + encodeURIComponent(last));
      }
      const crawledArticles = response.data;
      return crawledArticles;
    },
    async getNaverNews (keyword) {
      const response = await getAPI.get('crawled/naverrss?query=' + encodeURIComponent(keyword));
      const crawledArticles = response.data;
      return crawledArticles;
    },
    async getGoogleNews (keyword) {
      const response = await getAPI.get('crawled/googlerss?query=' + encodeURIComponent(keyword));
      const crawledArticles = response.data;
      return crawledArticles;
    },
    //bbs
    async postWrite (params) {
      const response = await getAPI.post('bbs/write', params)

      // console.log(response)
    },
    async getBbsList (last) {
      var response;
      if (last===null){
        response = await getAPI.get('bbs');
      } else {
        const params = {
          'last': last,
        }
        response = await getAPI.get('bbs?last=' + encodeURIComponent(last));
      }
      const bbsList = response.data;
      return bbsList;
    },

    async check () {
      const response = await getAPI.get('home/check')

      console.log(response)
    }
}