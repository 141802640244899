<template>
  <div class="home">
    <div class="interface-bar" v-if="!isWrite">
    <form class="search-form" >

      <input/>
      <div class="button">search</div>
    </form>
    <router-link class="button" :to="{name: 'BBSWrite'}">write</router-link>
    <!-- <router-link class="button" :to="{name: 'BBSRead'}">read</router-link> -->
    </div>
  <router-view :key="$route.path"/>
  </div>
</template>

<script>
// @ is an alias to /src

import Community from '@/services/Community.js'


export default {
  components: {
  },
  data () {
    return {
      // tableContents: [],
      // lastContent: null,
    }
  },
  methods: {
  },
  computed: {
    isWrite () {
      return this.$route.name==='BBSWrite' || this.$route.name==='BBSModify'
    }
  },
  created () {
    console.log('created')
  },
  activated () {
    console.log('activate')
    
  }  ,
}
</script>