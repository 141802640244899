import { createStore } from 'vuex'
import Community from '@/services/Community.js'
import CustomMath from '@/services/CustomMath.js'

export default createStore({
  state: {
    bbsContents: {
      tableContents: [],
      lastContent: null,
    }
  },
  mutations: {
    community_ADD_CONTENT(state, content){
      state.bbsContents.tableContents.push(content);
      state.bbsContents.lastContent = content.articleId.N;
      console.log('commit')
    }
  },
  actions: {
    async getBbsList ({ commit }) {
      console.log('getstart')
      Community.getBbsList(this.state.bbsContents.lastContent)
      .then(response => {
        for (var i=0; i < response.length; i++){
          response[i]['timegap'] = CustomMath.timeParse(response[i].articleId.N);
          this.commit('community_ADD_CONTENT', response[i]);
        }
      })
      console.log('getend')

    },
  },
  modules: {
  }
})
