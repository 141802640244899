<template>
    <!-- <div v-for="(exchange, exchangeKey) in pricelist" 
    :key="exchangeKey" class="table-row-item coinprice" :id="'row-' + exchangeKey">
        {{FormatPrice(pricelist[exchangeKey])}}
    </div> -->
    <div class="table-row-item-slot">
        <div class="table-row-upper">
            <div class="price-gap" :id="'ratio-'+ticker">+ {{((priceMaxMin.gap.ratio-1)*100).toFixed(1)}}%</div>
            <div class="table-row-item" :id="'highest-'+ticker">
                <div class="upper-row">
                    <div class="highest-prefix">
                        최고가
                    </div>
                    <div class="price-highest" >
                        {{numberCommaFixing(priceMaxMin.max.price)}}
                    </div>
                </div>
                <div class="lower-row">
                    {{priceMaxMin.max.exchanger}}
                </div>
            </div>
            <div class="table-row-item" :id="'lowest-'+ticker">
                <div class="upper-row">
                    <div class="lowest-prefix">
                        최저가
                    </div>
                    <div class="price-lowest" >
                        {{numberCommaFixing(priceMaxMin.min.price)}}
                    </div>
                </div>
                <div class="lower-row">
                    {{priceMaxMin.min.exchanger}}
                </div>
            </div>
                <div class="table-row-item button-cell">
                    <!-- <div v-if="isTableFolded" class="table-row-folding-button"  @click="TableFolding">
                        &#8964;
                    </div>
                    <div v-if="!isTableFolded" class="table-row-folding-button"  @click="TableFolding">
                        &#8963;
                    </div> -->

                    <div :class="{'table-row-folding-button':true, 'rotate-90':!isTableFolded, 'rotate-270':isTableFolded}"  @click="TableFolding">
                        &#60;
                    </div>
                </div>
        </div>
        <div v-bind:class="{'folded': isTableFolded, 'stretched': !isTableFolded}">
            <div v-if="!isTableFolded" class=table-row-under>
            <div  v-for="(exchange, exchangeKey) in pricelist" 
            :key="exchangeKey" class="table-row-item" :id="'row-' + exchangeKey">
                <div class="coinprice">
                    {{FormatPrice(pricelist[exchangeKey])}}
                </div>
                <div class="exchange">{{exchangeKey}}</div>
            </div>
            </div>
        </div>
    </div>


</template>

<script>
// @ is an alias to /src
import TableSorting from '@/assets/js/TableSorting.js'


export default {
  name: 'CoinTableRow',
  data () {
    return {
        isTableFolded: true,
    }
  },
  props: {
    pricelist: Object,
    KRWUSD: Number,
    ticker: String

  },
  components: {
  },
  methods: {
      TableFolding () {
          if(this.isTableFolded){
              this.isTableFolded = false;
          }
          else {
              this.isTableFolded = true;
          }
      },
    FormatPrice(pair) {
      return TableSorting.FormatPrice(pair, this.KRWUSD);
    },
    numberCommaFixing(x) {
        if (x===undefined){
            return 0;
        }
      return TableSorting.numberCommaFixing(x);
    },
  },
  computed: {
      priceMaxMin: function() {
        var exchangers = Object.keys(this.pricelist);
        var exchanger;
        var v;
        var max = {
            'exchanger': null,
            'price': null,
        }
        var min = {
            'exchanger': null,
            'price': null,
        }
        for (var i=0; i<exchangers.length; i++){
            exchanger = exchangers[i];
            if (this.pricelist[exchanger]===undefined) //currency key 없음 -> no coin info
            { 
                //pass
            }
            else{
                if (this.pricelist[exchanger]['currency']==='KRW') //korean won
                {
                    v = this.pricelist[exchanger]['v'];
                }else if (this.pricelist[exchanger]['currency']==='USD') //USDT
                {
                    v = this.pricelist[exchanger]['v']*this.KRWUSD;
                }

                //find max
                if (max['price'] < v)
                {
                    max['price'] = v;
                    max['exchanger'] = exchanger;
                } else if (max['price']===null)
                {
                    max['price'] = v;
                    max['exchanger'] = exchanger;
                }
                //find min
                if (min['price'] > v){
                    min['price'] = v;
                    min['exchanger'] = exchanger
                }else if (min['price']===null)
                {
                    min['price'] = v;
                    min['exchanger'] = exchanger;
                }
            }
                    
          }

        var gap = {'gap': max['price'] - min['price'],
                   'ratio': parseFloat(max['price']) / parseFloat(min['price'])
                   };

        return {'gap': gap,
                'max': max, 
                'min': min};
      },
  },
}
</script>
