import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Arbitrage from '../views/Arbitrage.vue'
import Longshort from '../views/Longshort.vue'
import Defy from '../views/Defy.vue'
import Unlisted from '../views/Unlisted.vue'
import BBSLayout from '../views/bbs/Layout.vue'
import BBSList from '../views/bbs/List.vue'
import BBSRead from '../views/bbs/Read.vue'
import BBSWrite from '../views/bbs/Write.vue'
import BBSModify from '../views/bbs/Modify.vue'
import BBSDelete from '../views/bbs/Delete.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/arbitrage',
    name: 'Arbitrage',
    component: Arbitrage
  },
  {
    path: '/longshort',
    name: 'Longshort',
    component: Longshort
  },
  {
    path: '/defy',
    name: 'Defy',
    component: Defy
  },
  {
    path: '/unlisted',
    name: 'Unlisted',
    component: Unlisted
  },
  {
    path: '/bbs',
    name: 'BBSLayout',
    props: true,
    component: BBSLayout,
    children: [
      {
        path:'',
        name: 'BBSList',
        component: BBSList
      },
      {
        path:'write',
        name: 'BBSWrite',
        component: BBSWrite
      },
      {
        path:':id',
        name: 'BBSRead',
        component: BBSList,
        children: [
          {
            path:'modify',
            name: 'BBSModify',
            component: BBSModify
          },
          {
            path:'delete',
            name: 'BBSDelete',
            component: BBSDelete
          }
        ]
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
